import React, { Component } from 'react';
import Logo from '../assets/img/logo.png';

class Navbar extends Component {
    render() {
        return (            
        <nav id="nav" className="navbar navbar-light navbar-expand-lg sticky-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="#root">
                    <img src={Logo} alt="logo"/>
                </a>
                <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcol-1">
                    <ul className="nav navbar-nav ml-auto">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" href="#root">Home</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" href="#skillsDiv">Skills</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" href="#educationDiv">Education</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" href="#portfolioDiv">Portfolio</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" href="#certificatesDiv">Certificates</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" href="#experienceDiv">Experience</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" href="#contactDiv">Contact Me</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        );
    }
}

export default Navbar;