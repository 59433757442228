import React, { Component, Fragment } from 'react';

class Skills extends Component {
    // state = {};
    render() {
        return (
            <Fragment >
                <div id="skillsDiv" className="spacing"></div>
                <div className="whiteBackground py-5">
                    <div className="container site_section" id="qualifications">
                        <h1 className="pb-3">Technical Skills</h1>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="border rounded-0 border-dark p-3 qual_title"><i className="fas fa-code fa-2x font-weight-bolder"></i>
                                    <h5 className="pt-2">Programming Languages</h5>
                                </div>
                                <div className="border rounded-0 border-dark p-3 qual_elements">
                                    <p className="mt-2">JavaScript</p>
                                    <p>Java</p>
                                    <p>C# (ASP.net Core)</p>
                                    <p>Python</p>
                                    <p>PHP</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="border rounded-0 border-dark p-3 qual_title"><i className="fa fa-picture-o fa-2x"></i>
                                    <h5 className="pt-2">Frameworks / Libraries</h5>
                                </div>
                                <div className="border rounded-0 border-dark p-3 qual_elements">
                                    <p className="mt-2">React</p>
                                    <p>Angular</p>
                                    <p>JQuery</p>
                                    <p>Node,js / Express.js</p>
                                    <p>Laravel</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="border rounded-0 border-dark p-3 qual_title"><i className="fas fa-pencil-ruler fa-pencil-ruler fa-2x"></i>
                                    <h5 className="pt-2">UI Development</h5>
                                </div>
                                <div className="border rounded-0 border-dark p-3 qual_elements">
                                    <p className="mt-2">Adobe Illustrator</p>
                                    <p>Adobe Photoshop</p>
                                    <p>Bootstrap Studio</p>
                                    <p>myBalsamiq</p>
                                    <p>Sass</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Skills;