import React, { Component, Fragment } from 'react';

class Experience extends Component {
    // state = {};
    render() {
        return (
            <Fragment>
                <div id="experienceDiv" className="spacing"></div>
                <div className="whiteBackground py-5">
                    <div className="container" id="experience">
                        <h1 className="mt-5 mb-5">Experience</h1>
                        <p><strong>IBM</strong>, Bedford, NS&emsp;&emsp;<strong>May 2019 - August 2019</strong></p>
                        <p><strong>IT Specialist</strong></p>
                        <p>Summer Co-operative Education Work Term</p>
                        <ul>
                            <li>Assigned to the PeopleSoft Practice at the Client Innovation Centre in Bedford, NS</li>
                            <li>Modified a Python program to accept and output new data</li>
                            <li>Created a new website to aid new employees in the onboarding process</li>
                            <li>Created a prototype of an application to solve seating issues</li>
                            <li>Assisted in the setup of laptops for new employees</li>
                            <li>Volunteered as camp counsellor for the week-long IBM S.T.E.M. Camp 
                                (<b>S</b>cience, <b>T</b>echnology, <b>E</b>ngineering, and <b>M</b>ath)</li>
                        </ul>
                    </div>
                </div>
            </Fragment>            
        );
    }
}

export default Experience;