import React, { Component } from 'react';

class Footer extends Component {
    // state = {};
    render() {
        return (
            <div className="row site_section">
                <div className="col">
                    <a href="https://www.facebook.com/chris.fredericks3" 
                       target="_blank" rel="noopener noreferrer">
                       <i className="fa fa-facebook-square m-2 fa-2x text-dark"></i>
                    </a>
                    <a href="https://www.facebook.com/chris.fredericks3" 
                       target="_blank" rel="noopener noreferrer">
                       <i className="fa fa-twitter-square m-2 fa-2x text-dark"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/chris-fredericks/" 
                       target="_blank" rel="noopener noreferrer">
                       <i className="fa fa-linkedin-square m-2 fa-2x text-dark"></i>
                    </a>
                    <p>Created by Chris Fredericks 2020</p>
                </div>
            </div>
        );
    }
}

export default Footer;