import React, { Component } from 'react';
import Profile from '../assets/img/PHOTO_ID.jpg';

class Home extends Component {
    // state = {};
    render() {
        return (
            <div id="profile">
                <div className="jumbotron">
                    <h1>My name's Chris. &nbsp;Nice to meet you.</h1>
                    <p>I'm a Web Programmer based in Truro, Nova Scotia. I pride myself in helping small businesses,
                         start-ups, and clients achieve high quality websites and exceptional user experience.</p>
                    <img id="profile_pic" src={Profile} alt="profilePic"/>
                    <p>Take a look at the projects I've been working on lately and lets work together!</p>
                    <p><a className="btn btn-danger text-dark" role="button" href="#portfolioDiv">My Portfolio</a></p>
                    <div className="row">
                        <div className="col">
                            <a href="https://www.facebook.com/chris.fredericks3" 
                            target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook-square m-2 fa-2x text-dark"></i>
                            </a>
                            <a href="https://twitter.com/freddy_ns" 
                            target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-twitter-square m-2 fa-2x text-dark"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/chris-fredericks/" 
                            target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-linkedin-square m-2 fa-2x text-dark"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default Home;