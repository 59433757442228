import React, { Component, Fragment } from 'react';
import axios from 'axios';
import resume from '../assets/documents/ChrisFredericksResume.pdf'

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
           fullName: '',
           email: '',
           message: '',
           success: false
        };
        this.inputFullNameRef = React.createRef();
        this.inputEmailRef = React.createRef();
        this.inputMessageRef = React.createRef();

        
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    async handleSubmit(event) {
        event.preventDefault();
        const {fullName, email, message} = this.state;

        const form = await axios.post('/api/form', {
            fullName,
            email,
            message
        });
        this.setState({
            fullName: '',
            email: '',
            message: '',
            success: true
        });
    }

    handleInputChange = (event) => {
        this.setState({
            success: false,
            [event.target.name]: event.target.value
        });
    }

    handleClearClick = (event) => {
        this.inputFullNameRef.current.value = '';
        this.inputEmailRef.current.value = '';
        this.inputMessageRef.current.value = '';
    }

    render() {
        const {fullName} = this.state;
        const {email} = this.state;
        const {message} = this.state;
        return (
            <Fragment>
                <div id="contactDiv" className="spacing"></div>
                <div className="blueBackground py-5">
                    <div className="container site_section qual_title" id="contact">
                        <div className="headings">
                            <h1>Contact Me</h1>
                        </div>
                        <div className="row test">
                            <div className="col-sm-7">
                                <div className="contact mx-4">
                                    <h3 className="mt-4">Let's work together!</h3>
                                    <p>If you need a website developed, 
                                        want some help with a current one, 
                                        or just want to say hi, 
                                        then don't hesitate to get in touch.
                                    </p>
                                    <form onSubmit={this.handleSubmit}>
                                        <label className="mt-3">Full Name</label>
                                        <input ref={this.inputFullNameRef} 
                                               className="form-control" 
                                               type="text" 
                                               name="fullName"
                                               value={fullName}
                                               placeholder="Your Name"
                                               onChange={this.handleInputChange}
                                               maxLength="50"
                                               required/>
                                        <label className="mt-2">Email Address</label>
                                        <input ref={this.inputEmailRef}
                                         className="form-control" 
                                               type="email" 
                                               name="email"
                                               value={email}
                                               placeholder="Email Address" 
                                               onChange={this.handleInputChange}
                                               maxLength="100"
                                               required/>
                                        <label className="mt-2">Message</label>
                                        <textarea ref={this.inputMessageRef} className="form-control" 
                                                  rows="5" 
                                                  id="message" 
                                                  name="message"
                                                  value={message} 
                                                  onChange={this.handleInputChange}
                                                  maxLength="400"
                                                  required></textarea>
                                        <button className="btn btn-danger my-3"
                                                type="submit">Submit
                                        </button>
                                        <button className="btn btn-warning mx-3"
                                                onClick={this.handleClearClick}>Clear
                                        </button>
                                    </form>
                                    { (this.state.success) && <div>
                                        <p style={{color: "green"}}>Message Sent!</p>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <a className="text-white btn btn-danger mb-3" href={resume} download>
                                <i className="fa fa-download pr-1"></i>
                                    Download Resume
                                </a>
                                <i className="fa fa-clipboard fa-3x mt-5 pl-3"></i>
                                <h3 className="mt-5 pt-3">Address:</h3>
                                <p>Chris Fredericks</p>
                                <p>9 Franklin Drive</p>
                                <p>Truro Heights, NS</p>
                                <p>B3B1P4</p>
                                <h3>Phone:</h3>
                                <p>(902) 890-0294</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Contact;