import React, { Component, Fragment } from 'react';
import CSharp from '../assets/img/csharp.png';

class Education extends Component {
    // state = {};
    render() {
        return (
            <Fragment>
                <div id="educationDiv" className="spacing"></div>
                <div className="blueBackground py-5">
                    <div className="container site_section" id="education">
                        <h1 className="pb-3">Education</h1>
                        <h3 className="pb-1">IT Web Programming, Nova Scotia Community College, Truro Campus, Truro, NS</h3>
                
                        <div className="row">
                            <div className="col-md-7">
                                <div className="border rounded-0 border-dark p-3 qual_title"><i className="fas fa-cogs fa-2x font-weight-bolder"></i>
                                    <h5 className="pt-2">Programming Courses</h5>
                                </div>
                                <div className="border rounded-0 border-dark p-3 qual_elements">
                                    <p className="mt-2">Logic and Programming &nbsp;(<i className="fab fa-python m-1 fa-lg"></i>)</p>
                                    <p>Client-Side Programming &nbsp;(<strong><i className="fab fa-js-square m-1 fa-lg"></i></strong>)</p>
                                    <p>Introduction to Object Oriented Programming &nbsp;(<i className="fab fa-java m-1 fa-lg"></i>)</p>
                                    <p>Full Stack Programming &nbsp;(<i className="fab fa-angular m-1 fa-lg"></i>)</p>
                                    <p>Web Application Programming I &nbsp;(<img id="csharp" src={CSharp} alt="C# logo" />ASP.net Core )</p>
                                    <p>Web Application Programming II &nbsp;(<strong><i className="fab fa-php m-1 fa-lg"></i></strong>)</p>
                                    <p>Developing for Content Management Systems &nbsp;(<strong><i className="fab fa-wordpress m-1 fa-lg"></i></strong>/<strong><i className="fab fa-drupal m-1 fa-lg"></i></strong>)</p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="border rounded-0 border-dark p-3 qual_title"><i className="fab fa-dev fa-2x"></i>
                                    <h5 className="pt-2">Development Courses</h5>
                                </div>
                                <div className="border rounded-0 border-dark p-3 qual_elements">
                                    <p className="mt-2">Introduction to Systems Analysis and Design</p>
                                    <p>User Interface Design and Development</p>
                                    <p>Data Fundamentals</p>
                                    <p>Operating Systems - Linux</p>
                                    <p>Introduction to Windows Administration </p>
                                    <p>Introduction to Networking and Security</p>
                                    <p>Professional Practices for IT</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Education;