import React, { Fragment } from 'react';

const Portfolio = (props) => {
    return (
        <Fragment>
            <div id="portfolioDiv" className="spacing"></div>
            <div className="whiteBackground py-5">
                <div className="container site_section qual_title" id="portfolio">
                    <div className="headings">
                        <h1>Portfolio Projects</h1>
                    </div>
                    <div className="row pt-3 test">
                        {props.projects.map((item) => (                            
                            <div key={item._id} className="containerPortfolio col-md-5 m-auto projects">
                                <img src={ require("../assets/img/" + item.imageName)} alt={item.projectName} className="image"/>
                                <div className="overlay">
                                    <h5>{item.projectName}</h5>
                                    {item.projectDescription}
                                    <h6 className="mt-3 text-light">Technologies Used:</h6>
                                    <h6 className="text-primary mb-3">{item.techsUsed}</h6>
                                    { (item.projectName === "U-Fish Website") && 
                                        <div>
                                            <a href={item.projectURL} className="btn btn-warning text-dark" target="_blank" rel="noopener noreferrer">
                                                Launch
                                            </a>
                                        </div>
                                    }
                                    { (item.projectName != "U-Fish Website") && 
                                        <div>
                                            <a href={item.projectURL} className="btn btn-warning text-dark" target="_blank" rel="noopener noreferrer">
                                                View on GitHub <strong><i className="fab fa-github m-1 fa-lg"></i></strong>
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}


export default Portfolio;