import React, { Component, Fragment } from 'react';
import ReactEssentials from '../assets/img/ReactEssentials.jpg';
import LearningReact from '../assets/img/LearningReact.jpg';
import ReactSPAs from '../assets/img/ReactSPAs.jpg';
import ReactRouter from '../assets/img/ReactRouter.jpg';
import MERN from '../assets/img/MERN.jpg';
import Python101 from '../assets/img/Python101.jpg';
import R101 from '../assets/img/R101.jpg';
import MachinePython from '../assets/img/MachinePython.jpg';
import Blockchain from '../assets/img/Blockchain.jpg';
// import Ambulance from '../assets/img/Ambulance.jpg';

class Certificates extends Component {
    // state = {};
    render() {
        return (
            <Fragment>
                <div id="certificatesDiv" className="spacing"></div>
                <div className="blueBackground py-5">
                    <div className="photo-gallery">
                        <div className="container site_section">
                            <div className="intro text-center">
                                <h1>Certificates</h1>
                                <p>Some of the online courses I've completed recently:</p>
                            </div>
                            <div className="row photos ml-5">
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={ReactEssentials}><img className="img-fluid" src={ReactEssentials} alt="React Essentials"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={LearningReact}><img className="img-fluid" src={LearningReact} alt="Learning React"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={ReactSPAs}><img className="img-fluid" src={ReactSPAs} alt="React SPAs"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={ReactRouter}><img className="img-fluid" src={ReactRouter} alt="React Router"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={MERN}><img className="img-fluid" src={MERN} alt="MERN"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={Python101}><img className="img-fluid" src={Python101} alt="Python 101"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={R101}><img className="img-fluid" src={R101} alt="R 101"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={MachinePython}><img className="img-fluid" src={MachinePython} alt="Machine Learning with Python"/></a>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={Blockchain}><img className="img-fluid" src={Blockchain} alt="Blockchain"/></a>
                                </div>
                                {/* <div className="col-sm-6 col-md-4 col-lg-3 item m-4 mr-5">
                                    <a data-lightbox="photos" href={Ambulance}><img className="img-fluid" src={Ambulance} alt="St John Ambulance"/></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Certificates;