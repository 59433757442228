import React, { Component } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Home from './sections/Home';
import Portfolio from './sections/Portfolio';
import Skills from './sections/Skills';
import Education from './sections/Education';
import Certificates from './sections/Certificates';
import Experience from './sections/Experience';
import Contact from './sections/Contact';
import Footer from './components/Footer';
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state= {
            projects: [],
            currentProject: {},   
        };

        this.updateCurrentProject = this.updateCurrentProject.bind(this);
    }

    componentDidMount() {
        const url = "https://chrisfredericks.ca/projects";
        // const url = "http://localhost:4000/projects";
        axios.get(url)
            .then((Response) => {
                this.setState({
                    projects: Response.data
                });
                console.log(Response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    updateCurrentProject(item) {
        this.setState({
            currentProject: item,
        });
    }

    render() {
        return (
            <div className="App">
                <Navbar />
                <Home />
                <Skills />
                <Education />
                <Portfolio projects={this.state.projects}
                           updateCurrentProject={this.updateCurrentProject}/>
                <Certificates />
                <Experience />
                <Contact />
                <Footer />
            </div>

        );
    }
}

export default App;
